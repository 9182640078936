import { Str } from '@supercharge/strings/dist';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, WritableComputedRef } from 'vue';
import Form from '~~/classes/Form';

export interface FormInputOptionalProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
}

export interface FormInputProps extends FormInputOptionalProps {
    form: Form<any>;
    model?: WritableComputedRef<any>;
    inputName: string;
    withNullOption?: boolean;
}

export interface FormInputData {
    props: FormInputProps;
    form: Form;
    model: WritableComputedRef<any>;
    label: ComputedRef<string>;
    inlineLabel: boolean;
    placeholder: ComputedRef<string>;
    inputId: ComputedRef<string>;
    inputName: string;
    inputType: string;
    step: number;
    className: ComputedRef<string>;
    elementClassName: ComputedRef<string>;
    containerClassName: ComputedRef<string>;
    disabled: boolean;
    withoutLabel: boolean;
    tooltip: string | null;
    autofocus: boolean;
    required: boolean;
    translated: boolean;
    hasTranslation: (translationLocale: string) => boolean;
    locale: Ref<string>;
    elementKey: ComputedRef<string>;
    withVariables: boolean;
}

export default function useFormInput(props: Readonly<Omit<FormInputProps, never>>, defaultProps?: FormInputOptionalProps) {
    const { $theme } = useNuxtApp();
    const { t } = useTranslation();

    /**
     * In case of translated inputs, we need to add the locale to the input name to make it unique.
     */
    const getFinalInputName = () => {
        return props.translated ? `${getFinalInputLabelKey()}.${locale.value}` : getFinalInputLabelKey();
    };

    /**
     * We are only interested in the last portion of the input name.
     * The dot notation is used to indicate nested objects.
     */
    const getFinalInputLabelKey = () => {
        return props.inputName.split('.').pop();
    };

    const className = computed(() => {
        if (props.className) {
            return props.className;
        }
        if (defaultProps?.className) {
            return defaultProps?.className;
        }
        return props.disabled
            ? $theme('form.input_disabled')
            : [
                  $theme('form.input'),
                  {
                      '!border-red-600': props.form.errors?.has(props.inputName),
                  },
              ];
    });
    const elementClassName = computed(() => {
        return props.elementClassName || $theme('form.containers.element');
    });
    const containerClassName = computed(() => {
        return props.containerClassName || $theme('form.containers.input');
    });
    const label = computed(() => {
        return props.label || t(`form.labels.${getFinalInputLabelKey()}`);
    });
    const placeholder = computed(() => {
        return props.placeholder || label.value;
    });
    const inputId = computed(() => {
        return props.inputId || uuidv4();
    });
    const { authed } = storeToRefs(useAuthStore());
    const locale = ref(authed.value?.locale ?? useGetDefaultLocale());

    const elementKey = computed(() => {
        return Str(props.inputName).slug().append(locale.value).get();
    });

    const model = computed({
        get() {
            if (props.translated) {
                return props.form.get(`${props.inputName}.${locale.value}`);
            }
            return props.form.get(props.inputName);
        },
        set(value) {
            //   console.log(
            //     "useFormInput set model value",
            //     props.inputName,
            //     props.translated,
            //     locale.value,
            //     value,
            //   );
            if (props.translated) {
                return props.form.set(`${props.inputName}.${locale.value}`, value);
            }
            return props.form.set(props.inputName, value);
        },
    });

    const hasTranslation = (translationLocale: string) => {
        return props.translated && props.form.get(`${props.inputName}.${translationLocale}`);
    };

    const data: FormInputData = {
        props,
        model,
        form: props.form,
        className,
        elementClassName,
        containerClassName,
        label,
        placeholder,
        inlineLabel: props.inlineLabel ?? false,
        inputId,
        inputName: props.inputName,
        inputType: props.inputType ?? 'text',
        step: props.step ?? 1,
        disabled: props.disabled,
        withoutLabel: props.withoutLabel ?? false,
        tooltip: props.tooltip ?? null,
        autofocus: props.autofocus ?? false,
        required: props.autofocus ?? false,
        translated: props.translated ?? false,
        hasTranslation,
        locale,
        elementKey,
        withVariables: props.withVariables ?? false,
    };

    return data;
}

export { FormInputProps, FormInputData, useFormInput };
